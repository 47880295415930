import { create } from 'zustand';

type LayoutStore = {
  isDataDisplayOpen: boolean;
  setIsDisplayOpen: (isOpen: boolean) => void;
  setIsExpanderRendered: (isRendered: boolean) => void;
  isExpanderRendered: boolean;
  resizeEvent: number;
  triggerResizeEvent: () => void;
  hoveredGuid: string | null;
  setHoveredGuid: (guid: string | null) => void;
};

export const useLayoutStore = create<LayoutStore>((set) => ({
  isDataDisplayOpen: false,
  setIsDisplayOpen: (isOpen) => set({ isDataDisplayOpen: isOpen }),
  isExpanderRendered: false,
  setIsExpanderRendered: (isRendered) => set({ isExpanderRendered: isRendered }),
  resizeEvent: 0,
  triggerResizeEvent: () => set((state) => ({ resizeEvent: state.resizeEvent + 1 })),
  hoveredGuid: null,
  setHoveredGuid: (guid) => set({ hoveredGuid: guid })
}));
