import { API_URL } from 'src/lib/config';
import { HotelBookingMethod } from 'src-new/globalModels/common/hotelBookingMethod';
import { EmailContactListType } from 'src-new/features/activites/api/useGetEmailContactListQuery';
import { HotelBookingType } from 'src-new/globalModels/common/hotelBookingType';
import { CommunicationType } from 'src-new/globalModels/common/communicationType';
import { SignatureType } from 'src-new/features/activites/editActivity/sendEmail/types';

const queryStringArray = (name: string, array: any[]) => {
  if (!array || array.length === 0) {
    return ``;
  }
  let toReturn = '';
  array.forEach((val) => {
    toReturn += `${name}=${val}&`;
  });
  return toReturn;
};

const queryStringParam = (name: string, value: string | number | undefined) => {
  if (!value) {
    return `${name}=`;
  }
  return `${name}=${value}`;
};

const roots = {
  activityNew: (part: string, id: number | undefined) => {
    return id ? `/api/activities/${id}/${part}` : `/api/activities/${part}`;
  },
  selectOption: '/api/selectoption'
};

export const ApiEndpoints = {
  //Old UI stuff
  selectLists: {
    usersInRoles: '/api/SelectList/UsersInRoles',
    legacyCountries: '/api/SelectList/legacy/address/countries',
    legacyProvinces: (countryId: number) => `/api/SelectList/legacy/address/${countryId}/provinces`,
    legacyCities: (provindeId: number) => `/api/SelectList/legacy/address/${provindeId}/cities`
  },
  //new UI stuff
  selectOptions: {
    userAdmin: {
      mainDbRoles: 'admin/user/filteredroles',
      obtRoles: 'admin/obtuser/filteredroles'
    },
    address: {
      countries: 'api/selectoption/address/countries',
      provinces: (countryId: number | undefined) => `api/selectoption/address/${countryId}/provinces`,
      cities: (provinceId: number | undefined): string => `api/selectoption/address/${provinceId}/cities`
    },
    hotels: {
      cancellationPolicies: 'api/selectoption/hotels/cancellationpolicies',
      roomTypes: 'api/selectoption/hotels/roomtypes',
      hotelList: 'api/selectoption/hotels',
      hotelInCity: (cityId: number) => `api/selectoption/hotels/city/${cityId}`,
      hotelInCities: (cityIds: number[]) => `api/selectoption/hotels/cities?${queryStringArray('cityIds', cityIds)}`,
      chainCodes: `api/selectoption/hotels/chaincodes`
    },
    activities: {
      cancellationPolicy: `${roots.selectOption}/activities/CancellationPolicy`,
      itineraryHeaders: `${roots.selectOption}/activities/itinerary/headers`,
      activityStatus: (activityId: number | undefined) =>
        activityId
          ? `${roots.selectOption}/activities/${activityId}/status`
          : `${roots.selectOption}/activities/new/status`,
      activityAssign: (activityId: number | undefined, allowSelectingDisabled?: boolean) =>
        activityId
          ? `${roots.selectOption}/activities/${activityId}/AssignTo?allowSelectingDisabled=${allowSelectingDisabled ?? false}`
          : `${roots.selectOption}/activities/new/AssignTo?allowSelectingDisabled=${allowSelectingDisabled ?? false}`
    },
    companies: {
      list: 'api/SelectOption/Companies',
      companyContactsSearch: (
        companyId: number | undefined | null,
        approvedBookerOnly: boolean,
        hotelBookingType: HotelBookingType | undefined,
        allowSelectingDisabled?: boolean
      ) =>
        `api/SelectOption/companies/contacts/${companyId}?hotelBookingType=${hotelBookingType == undefined ? HotelBookingType.Business : hotelBookingType}&approvedBookerOnly=${approvedBookerOnly}&allowSelectingDisabled=${allowSelectingDisabled ?? false}`,
      companyContactsList: (
        companyId: number | undefined | null,
        approvedBookerOnly: boolean,
        allowSelectingDisabled?: boolean
      ) =>
        `api/SelectOption/companies/contacts/${companyId}/list/?approvedBookerOnly=${approvedBookerOnly}&allowSelectingDisabled=${allowSelectingDisabled ?? false}`
    },
    formsOfPayment: 'api/SelectOption/FormsOfPayment',
    loyaltyAccount: 'api/SelectOption/LoyaltyAccount',
    companyReportingRequirement: (companyId: number) => `api/SelectOption/CompanyReportingRequirement/${companyId}`,
    cities: () => `api/selectoption/cities`,
    usersInRoles: (roles: string[]) => {
      let toReturn = `/api/selectoption/UsersInRoles`;
      roles.forEach((role) => {
        if (toReturn.indexOf('?') >= 0) {
          toReturn += `&roles=${role}`;
        } else {
          toReturn += `?roles=${role}`;
        }
      });
      return toReturn;
    },
    enum: (typeName: string, includeZero: boolean = false) =>
      `/api/selectoption/Enum/${typeName}?includeZero=${includeZero}`
  },
  leads: {
    genericCrud: '/leads',
    filteredListPost: (page: number, pageSize: number, orderBy: string | undefined, isDesc: boolean | undefined) => {
      let toReturn = `/leads/filter?page=${page}&pageSize=${pageSize}`;
      if (orderBy !== undefined) {
        toReturn += `&orderBy=${orderBy}`;
      }
      if (isDesc !== undefined) {
        toReturn += `&isDesc=${isDesc}`;
      }
      return toReturn;
    },
    contacts: (leadId: number) => `/leads/${leadId}/contacts`,
    offices: (leadId: number) => `/leads/${leadId}/offices`
  },
  CompanyCrm: {
    companyContact: {
      filteredContactListPost: (
        CompanyId: number,
        page: number,
        pageSize: number,
        orderBy: string | undefined,
        isDesc: boolean | undefined
      ) => {
        let toReturn = `/CompanyContact/${CompanyId}/filter?page=${page}&pageSize=${pageSize}`;
        if (orderBy !== undefined) {
          toReturn += `&orderBy=${orderBy}`;
        }
        if (isDesc !== undefined) {
          toReturn += `&isDesc=${isDesc}`;
        }
        return toReturn;
      },
      setCorporatePCard: (companyId: number, contactId: number) =>
        `/CompanyContact/${companyId}/contacts/${contactId}/setPCard`
    },
    personalContact: {
      filteredContactListPost: (
        CompanyId: number,
        page: number,
        pageSize: number,
        orderBy: string | undefined,
        isDesc: boolean | undefined
      ) => {
        let toReturn = `/PersonalContact/${CompanyId}/filter?page=${page}&pageSize=${pageSize}`;
        if (orderBy !== undefined) {
          toReturn += `&orderBy=${orderBy}`;
        }
        if (isDesc !== undefined) {
          toReturn += `&isDesc=${isDesc}`;
        }
        return toReturn;
      }
    },
    preferredHotel: {
      filteredListPost: (
        CompanyId: number,
        page: number,
        pageSize: number,
        orderBy: string | undefined,
        isDesc: boolean | undefined
      ) => {
        let toReturn = `/CompanyPreferredHotel/${CompanyId}/filter?page=${page}&pageSize=${pageSize}`;
        if (orderBy !== undefined) {
          toReturn += `&orderBy=${orderBy}`;
        }
        if (isDesc !== undefined) {
          toReturn += `&isDesc=${isDesc}`;
        }
        return toReturn;
      },
      roomRates: (companyId: number, hotelId: number) => `/CompanyPreferredHotel/${companyId}/roomrates/${hotelId}`
    },
    unpreferredHotel: {
      filteredListPost: (
        CompanyId: number,
        page: number,
        pageSize: number,
        orderBy: string | undefined,
        isDesc: boolean | undefined
      ) => {
        let toReturn = `/CompanyUnPreferredHotel/${CompanyId}/filter?page=${page}&pageSize=${pageSize}`;
        if (orderBy !== undefined) {
          toReturn += `&orderBy=${orderBy}`;
        }
        if (isDesc !== undefined) {
          toReturn += `&isDesc=${isDesc}`;
        }
        return toReturn;
      },
      createOrUpdate: (companyId: number) => `/CompanyUnPreferredHotel/${companyId}/createorupdate`
    },
    CompanyOffice: {
      genericCrud: '/CompanyOffice',
      list: (companyId: number) => `/CompanyOffice/${companyId}/list`
    },
    CompanyNotes: {
      genericCrud: '/CompanyNotes',
      list: (companyId: number) => `/CompanyNotes/${companyId}/filter`,
      create: (companyId: number) => `/CompanyNotes/${companyId}/create`,
      update: (companyId: number) => `/CompanyNotes/${companyId}/update`
    },
    BasicInfo: {
      genericCrud: '/CompanyBasicInfo'
    },
    Reservation: {
      genericCrud: '/CompanyReservation'
    },
    Accounting: {
      genericCrud: '/CompanyAccounting'
    },
    Policy: {
      genericCrud: '/CompanyPolicy'
    },
    Company: {
      genericCrud: '/Companies',
      filteredListPost: (page: number, pageSize: number, orderBy: string | undefined, isDesc: boolean | undefined) => {
        let toReturn = `/companycrm/filter?page=${page}&pageSize=${pageSize}`;
        if (orderBy !== undefined) {
          toReturn += `&orderBy=${orderBy}`;
        }
        if (isDesc !== undefined) {
          toReturn += `&isDesc=${isDesc}`;
        }
        return toReturn;
      }
    }
  },

  personalCompany: {
    genericCrud: '/personalCompanies',
    filteredListPost: (page: number, pageSize: number, orderBy: string | undefined, isDesc: boolean | undefined) => {
      let toReturn = `/company/filter?page=${page}&pageSize=${pageSize}`;
      if (orderBy !== undefined) {
        toReturn += `&orderBy=${orderBy}`;
      }
      if (isDesc !== undefined) {
        toReturn += `&isDesc=${isDesc}`;
      }
      return toReturn;
    },

    contacts: {
      put: (companyId: number, contactId: number) => `/personalCompanies/${companyId}/contacts/${contactId}`,
      setCard: (companyId: number, contactId: number) =>
        `/personalCompanies/${companyId}/contacts/${contactId}/cardOnFile`
    }
  },
  activities: {
    recordLocatorCheck: (reservationNumber: string) => `/api/activity/ReclocCheck?recordLocator=${reservationNumber}`,
    addInquiryNote: '/api/QuoteManagement/AddInquiryNote',
    splitInquiryNote: '/api/QuoteManagement/SplitFromQuote',
    getActivity: '/api/activity',
    getActivityPage: '/api/activity/page',
    getActivityTravellersInformation: `/api/ActivityInformation/travellers/`,
    getActivityIdsFromFlsq: (id: number) => `/api/activity/get/flsq/${id}`,
    getRelatedActivityIds: (id: number) => `/api/activity/get/relatedActivities/${id}`,
    getActivityInfoEmailBody: (id: number) => `/api/ActivityInformation/emailBody/${id}`,
    getActivityInfoEmailDisplay: (id: number) => `/api/ActivityInformation/DisplayEmail/${id}`,
    getActivityInfoEmailDisplayByActivity: (id: number) => `/api/ActivityInformation/displayemail/activityid/${id}`,
    getActivityInfoHotelPrefrences: `/api/ActivityInformation/`,
    getActivityHotelInformation: (id: number) => `/api/activityinformation/hotelinfo/${id}`,
    getActivityAuthorizedBookerInformation: (id: number) => `/api/ActivityInformation/authorizedbooker/${id}`,
    newInquiry: '/api/newinquiry',
    getActivityPolicyInformation: (id: number) => `/api/ActivityInformation/${id}`,
    activityStatus: (activityId: number) => `/api/activitystatus/${activityId}`
  },
  company: {
    policy: (id: number) => `/api/Company/Policy/${id}`,
    info: (id: number) => `/api/Company/${id}/info/hotelSearch/`,
    projects: (id?: number, cityName: string[] = [], cityIds: number[] = [], start?: string, end?: string) =>
      `/api/Company/${id}/projects${
        cityName || start || end
          ? `?${[
              cityIds.length ? cityIds.map((city) => `cityIds=${encodeURIComponent(city)}`).join('&') : '',
              cityName.length ? cityName.map((city) => `cityName=${encodeURIComponent(city)}`).join('&') : '',
              start && `start=${encodeURIComponent(start)}`,
              end && `end=${encodeURIComponent(end)}`
            ]
              .filter(Boolean)
              .join('&')}`
          : ''
      }`
  },
  activitiesNew: {
    edit: {
      get: (activityId: number) => roots.activityNew('edit', activityId),
      put: (activityId: number | undefined) => roots.activityNew('edit', activityId),
      post: () => roots.activityNew('edit', undefined)
    },
    inboxManager: {
      activity: {
        add: (activityId: number) => `/api/InboxManagerPriorityList/activity/${activityId}`,
        delete: (activityId: number) => `/api/InboxManagerPriorityList/activity/${activityId}`
      },
      reorder: () => `/api/InboxManagerPriorityList`
    },
    utility: {
      sendToFolio: (activityId: number) => `${roots.activityNew('utility', activityId)}/SendToFolio`,
      setFollowUp: (activityId: number) => `${roots.activityNew('utility', activityId)}/followup`,
      getEmailBody: (activityId: number) => `${roots.activityNew('utility', activityId)}/EmailBody`,
      sendToGroups: (activityId: number) => `${roots.activityNew('utility', activityId)}/SendToGroups`,
      duplicate: (activityId: number, count: number) =>
        `${roots.activityNew('utility', activityId)}/duplicate/${count}`,
      delete: (activityId: number, reason: string) =>
        `${roots.activityNew('utility', activityId)}/delete?reason=${reason}`,
      cancel: (activityId: number) => `${roots.activityNew('utility', activityId)}/cancel`,
      split: (activityId: number) => `${roots.activityNew('utility', activityId)}/split`,
      merged: (destinationActivityId: number, sourceActivityIds: number[], isFolio: boolean) =>
        `${roots.activityNew('utility', destinationActivityId)}/merge?${queryStringArray('toMerge', sourceActivityIds)}isFolio=${isFolio}`,
      splitNote: (activityId: number, noteId: number, removeFromSource: boolean) =>
        `${roots.activityNew('utility', activityId)}/notes/${noteId}/split?removeFromSource=${removeFromSource}`,
      deleteNoteButNotReally: (activityId: number, noteId: number) =>
        `${roots.activityNew('utility', activityId)}/notes/${noteId}/deletebutnotreally`,
      logs: (activityId: number) => `/api/Auditing/activity/${activityId}`,
      emailSendTo: (
        activityId: number,
        listType: EmailContactListType,
        travellerIds: number[],
        approvedBookerId: number | undefined,
        authorizedBookerId: number | undefined,
        replyToNoteId: number | undefined
      ) =>
        `${roots.activityNew('EmailSendTo', activityId)}/${listType}/?${queryStringParam('approvedBookerId', approvedBookerId)}&${queryStringParam('authorizedBookerId', authorizedBookerId)}&${queryStringParam('replyToId', replyToNoteId)}&${queryStringArray('travellerIds', travellerIds)}`
    },
    clientCommunication: {
      sendClient: (activityId: number) => `${roots.activityNew('SendCommunication', activityId)}/client`,
      sendHotel: (activityId: number) => `${roots.activityNew('SendCommunication', activityId)}/hotel`
    },
    travellers: {
      checkName: (companyId: number, activityId?: number) =>
        `${roots.activityNew('travellers', activityId)}/${companyId}/namecheck`,
      createUnverified: (activityId: number | undefined, companyId: number) =>
        `${roots.activityNew('travellers', activityId)}/${companyId}`,
      duplicateBooking: (
        travellerIds: number[],
        checkInDate: string,
        checkOutDate: string,
        existingActivityId: number | undefined
      ) =>
        `${roots.activityNew('travellers', existingActivityId)}/duplicatebooking?${queryStringArray('travellerIds', travellerIds)}checkInDate=${checkInDate}&checkOutDate=${checkOutDate}`
    },
    travellersAndPaymentInfo: {
      loadInitialData: (travellerIds: number[], companyId: number, activityId: number) =>
        `/api/activities/travellersandpaymentinfo/loadInitialData/${companyId}/${activityId}?${queryStringArray('travellerIds', travellerIds)}`,
      savePrimaryTravellerId: () => `/api/activities/travellersandpaymentinfo/savePrimaryTravellerId`,
      savePrimaryTravellerLoyaltyAccounts: () =>
        `/api/activities/travellersandpaymentinfo/savePrimaryTravellerLoyaltyAccounts`
    },
    reportingRequirements: {
      get: (activityId: number) => roots.activityNew('ReportingRequirements', activityId),
      getNew: (companyId: number, travellerIds: number[]) =>
        `${roots.activityNew('ReportingRequirements', undefined)}?companyId=${companyId}&${queryStringArray('travellerIds', travellerIds)}`,
      put: (activityId: number) => roots.activityNew('ReportingRequirements', activityId)
    },
    itinerary: {
      get: (activityId: number, rateId: number | undefined) =>
        rateId
          ? `${roots.activityNew('Itinerary', activityId)}?rateId=${rateId}`
          : roots.activityNew('Itinerary', activityId),
      put: (activityId: number) => `${roots.activityNew('Itinerary', activityId)}`,

      getTaxes: (activityId: number) => `${roots.activityNew('Itinerary', activityId)}/taxes`,

      book: (activityId: number, rateId: number, bookingMethod: HotelBookingMethod) => {
        switch (bookingMethod) {
          case HotelBookingMethod.Manual:
            return `${roots.activityNew('book', activityId)}/${rateId}/manual`;
          case HotelBookingMethod.AgentBookingPassive:
            return `${roots.activityNew('book', activityId)}/${rateId}/passive`;
          case HotelBookingMethod.AgentBookingActive:
            return `${roots.activityNew('book', activityId)}/${rateId}/active`;
          default:
            throw new Error('Invalid hotel booking method');
        }
      },
      preCheck: (activityId: number, rateId: number) => `${roots.activityNew('book', activityId)}/${rateId}/check`,
      preview: (activityId: number, communicationType: CommunicationType, rateId?: number, isCancel?: boolean) =>
        rateId
          ? `${roots.activityNew('Itinerary', activityId)}/preview/${communicationType}/${rateId}`
          : `${roots.activityNew('Itinerary', activityId)}/preview/${communicationType}/?isCancel=${isCancel == undefined ? false : isCancel}`
    },
    hotelRates: {
      get: (activityId: number) => roots.activityNew('Rates', activityId),
      getSelected: (activityId: number) => `${roots.activityNew('Rates', activityId)}/selected`,
      put: (activityId: number, rateId: number) => `${roots.activityNew('Rates', activityId)}/${rateId}`,
      daily: {
        put: (activityId: number, rateId: number) => roots.activityNew(`rates/daily/${rateId}`, activityId)
      },
      copy: (activityId: number, rateId: number) => `${roots.activityNew('rates', activityId)}/copy/${rateId}`,
      delete: (activityId: number, rateId: number) => `${roots.activityNew('rates', activityId)}/delete/${rateId}`,
      post: (activityId: number) => roots.activityNew('Rates', activityId)
    },
    hotelSearch: {
      searchInformation: (activityId: number) => `${roots.activityNew('hotelSearch', activityId)}/searchInformation`,
      search: (activityId: number) => `${roots.activityNew('hotelSearch', activityId)}`,
      createUnverified: (activityId: number, forQuote: boolean) =>
        `${roots.activityNew('hotelSearch', activityId)}/hotel/?forQuote=${forQuote}`
    },
    information: {
      companyPreference: (companyId: number) =>
        `${roots.activityNew('information', undefined)}/company/${companyId}/hotels/preference`,
      company: (companyId: number) => `${roots.activityNew('information', undefined)}/company/${companyId}`,
      hotels: (hotelIds: number[]) =>
        `${roots.activityNew('information', undefined)}/hotels?${queryStringArray('hotelIds', hotelIds)}`,
      travellers: (travellerIds: number[]) =>
        `${roots.activityNew('information', undefined)}/travellers?${queryStringArray('travellerIds', travellerIds)}`
    },

    quote: {
      sendQuote: (activityId: number) => `${roots.activityNew('quote', activityId)}/send`,
      preview: (activityId: number, communicationType: CommunicationType) =>
        `${roots.activityNew('quote', activityId)}/preview/${communicationType}`
    }
  },
  activityAdmin: {
    removeCancellation: (activityId: number) => `${roots.activityNew('admin', activityId)}/RemoveCancellation`,
    removeItinerary: (activityId: number) => `${roots.activityNew('admin', activityId)}/RemoveItinerary`
  },
  activityReport: (page: number, rowsPerPage: number) => `/api/activityreport?page=${page}&pageSize=${rowsPerPage}`,
  compose: {
    post: '/api/ComposeEmail/',
    get: {
      defaultValue: (activityId: number, communicationType: CommunicationType) =>
        `/api/ComposeEmail/default?activityId=${activityId}&communicationType=${communicationType}`,
      signatures: (signatureType: SignatureType) => `/api/ComposeEmail/signatures/?signatureType=${signatureType}`,
      replyTo: (activityId: number) => `/api/ComposeEmail/replyTo?activityId=${activityId}`,
      attachments: (activityId: number) => `/api/ComposeEmail/attachments?activityId=${activityId}`,
      replyToBody: (id: number) => `/api/ComposeEmail/replyToBody?replyToId=${id}`
    }
  },
  quoteInquiry: {
    addRate: '/api/QuoteInquiry/AddRates',
    previewQuote: '/QuoteItineraryPreview/Quote',
    previewItinerary: (selectedRateId: number) => '/QuoteItineraryPreview/Itinerary?selectedRateId=' + selectedRateId,
    sendItinerary: '/api/SendEmailQuoteItinerary/Itinerary/',
    getSendItinerary: '/api/SendEmailQuoteItinerary/GetItinerary/',
    getSendQuote: '/api/SendEmailQuoteItinerary/GetQuote/',
    sendQuote: '/api/SendEmailQuoteItinerary/Quote'
  },
  hotels: {
    hotelInfo: '/api/ActivityInformation/hotelinfo',
    hotelSearch: '/api/hotelsearch',
    crm: {
      roomTypes: {
        getForHotel: (hotelId: number) => `/api/hotels/crm/${hotelId}/roomtypes`,
        get: (hotelId: number, roomTypeId: number) => `/api/hotels/crm/${hotelId}/roomtypes/${roomTypeId}`,
        put: (hotelId: number, roomTypeId: number) => `/api/hotels/crm/${hotelId}/roomtypes/${roomTypeId}`,
        post: (hotelId: number) => `/api/hotels/crm/${hotelId}/roomtypes`,
        rates: {
          get: (hotelId: number, roomTypeId: number, rateId: number) =>
            `/api/hotels/crm/${hotelId}/roomtypes/${roomTypeId}/rates/${rateId}`,
          put: (hotelId: number, roomTypeId: number, rateId: number) =>
            `/api/hotels/crm/${hotelId}/roomtypes/${roomTypeId}/rates/${rateId}`,
          post: (hotelId: number, roomTypeId: number) => `/api/hotels/crm/${hotelId}/roomtypes/${roomTypeId}/rates`
        }
      }
    }
  },

  announcement: {
    get: '/api/admin/Announcement'
  },
  mirAdmin: {
    mirFileReport: '/api/MirAdmin/MirFileReport',
    mirAgentErrorReport: '/api/MirAgentErrorReport',
    mirActivityReport: '/api/MirAdmin/ActivityMissingMirReport',
    put: '/api/MirAdmin',
    delete: (id: number) => `/api/MirAdmin/${id}`,
    import: '/api/MirAdmin/RunImport'
  },
  crmAdmin: {
    loyaltyAccountManagement: '/crmadmin/loyaltynumbertypes'
  },
  hubs: {
    activityNotificationHub: API_URL + '/hubs/ActivityOpenNotificationHub',
    activityReplyNotificationHub: API_URL + '/hubs/ActivityReplyNotificationHub',
    workFlowHub: API_URL + '/hubs/WorkflowHub'
  },
  reporting: {
    agentActivityReport: {
      get: (startDate: string, endDate: string) => `/api/AgentActivityReport?startDate=${startDate}&endDate=${endDate}`,
      send: (startDate: string, endDate: string) =>
        `/api/AgentActivityReport/Send?startDate=${startDate}&endDate=${endDate}`
    }
  }
};
